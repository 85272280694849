@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Gotham";
  src: url('./assets/font/GothamBook.ttf') format('truetype');;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Black";
  src: url('./assets/font/Gotham-Black.otf') format('truetype');;
  font-weight: bold;
  font-style: bold;
}
@media (max-width: 767px) {
  body {
    background-color: #FFFFFF;
  }
}

@media (min-width: 767px) {
  body {
    background-color: #F5F5F5;
  }
}

html{
  scroll-behavior: smooth;
  scroll-padding: 50px;
}


.slide{
  max-width: 100%;
  height: auto;
  opacity: 0 ;
  transition: opacity 0.5s ease-in-out;
}

.slide.active{
  opacity: 1 ;
}

input{
  color: black;
  text-decoration-color: black;
}


html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  display: none;
}






form input, textarea, select{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px auto;
  padding: 4px;
  padding-left: 5px;
  border-radius: 5px;
  font-family: "gotham";
  font-size: 15px;
  color: #D9D9D9;
  border: 1px solid #D9D9D9;

}

select{
  color: black;
}

option{
  color: black;
}

.header-container {
  width: 200vw;
}

.gotham-Black{
  font-family: "Gotham-Black";
}

.gotham{
  font-family: "Gotham";
}